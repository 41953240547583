// extracted by mini-css-extract-plugin
export var accountLogin = "index-module--accountLogin--pEo8a";
export var accountLoginWrapper = "index-module--accountLoginWrapper--DB9K5";
export var btnContent = "index-module--btnContent--KXdFv";
export var btnText = "index-module--btnText--qILaS";
export var contentWrapper = "index-module--contentWrapper--dG90x";
export var continueBtn = "index-module--continueBtn--PnJVJ";
export var demoTitle = "index-module--demoTitle--EEcC6";
export var descList = "index-module--descList--UWHYd";
export var descListItem = "index-module--descListItem--Trkqd";
export var descListItemIcon = "index-module--descListItemIcon--mwhA8";
export var descListItemText = "index-module--descListItemText--iXpX+";
export var descTitle = "index-module--descTitle--twHaL";
export var directlyBy = "index-module--directlyBy--dpVVI";
export var directlyByDesc = "index-module--directlyByDesc--pfCpH";
export var directlyByEmailIcon = "index-module--directlyByEmailIcon--+0sJL";
export var directlyByIconDesc = "index-module--directlyByIconDesc--95S3b";
export var directlyByIconWrapper = "index-module--directlyByIconWrapper--3UbnW";
export var directlyByPhoneIcon = "index-module--directlyByPhoneIcon--6u3Qn";
export var directlyByTitle = "index-module--directlyByTitle--RazGZ";
export var errorA = "index-module--errorA--Gtg9c";
export var formTitle = "index-module--formTitle--ZnfKY";
export var formWrapper = "index-module--formWrapper--7EWRL";
export var googleButton = "index-module--googleButton--nHpIG";
export var lightText = "index-module--lightText--ypf3l";
export var line = "index-module--line--tCiZp";
export var loading = "index-module--loading--HJ1fZ";
export var loginContentWrapper = "index-module--loginContentWrapper--rIYLO";
export var orLine = "index-module--orLine--2TYPa";
export var orText = "index-module--orText--Y1SXC";
export var protocol = "index-module--protocol--m2bF6";
export var replaceGoogleBtn = "index-module--replaceGoogleBtn--6+tGA";
export var tips = "index-module--tips--rw-GD";
export var toTop = "index-module--toTop--BYiqn";
export var whatsAppIcon = "index-module--whatsAppIcon--Kbbib";
export var whatsAppText = "index-module--whatsAppText--CIr3X";
export var whatsAppWrapper = "index-module--whatsAppWrapper--Sfu6Z";
export var zIndex99 = "index-module--zIndex99--DVYWz";
export var zIndexF2 = "index-module--zIndexF2--8j64-";