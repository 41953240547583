// 表单接口提交 - 重点关注行业 - 对应表
export const INDUSTRY_MAP = {
  金融: 'oO3S05KQ9',
  运营商: '1uh0Mqn72',
  广电: 'W6Ou2bPmA',
  品牌零售: '6K2684938',
  汽车: 'bg124Eib2',
  '医药/大健康零售': 't6p83bTtM',
  '电商/跨境电商': 'lc94d3B9v',
  政府服务: 'cmNRW0tUM',
  在线文娱: '2Qsf617zt',
  游戏: 's15NlCNMp',
  在线教育: 'LMmmjsslD',
  餐饮: '50Wdp5YN1',
  其他: 'other',
}

// 表单接口提交 - 业务方向 - 对应表
export const BUSINESS_MAP = {
  '软件 SaaS': 'mJSib8ZOu',
  私有化订阅: 'Wbub59i86',
  私有化买断: 'Ngr4evVVt',
  硬件产品: 'MTeyl8b22',
  '专业服务（如代运营、咨询等）': 'st8s1qBz1',
  其他: 'other',
}

// 表单接口提交 - 客单价区间 - 对应表
export const PRICE_INTERVAL_MAP = {
  '10 万以下': 'option1',
  '10 万 - 50 万': '8z2l9Kc2n',
  '50 万以上': '82zje339w',
}

// 表单接口提交 - 注册时间 - 对应表
export const REGISTRATION_TIME_MAP = {
  '5 年以下': 'option1',
  '5 年以上': '1s1stVIU2',
}

// 表单接口提交 - 注册资本 - 对应表
export const REGISTERED_CAPITAL_MAP = {
  '500 万以下': 'option1',
  '500 万 - 1000 万': 'ewt32Y8o8',
  '1000 万以上': 'ymK316a85',
}

// 表单接口提交 - 销售人员规模 - 对应表
export const STAFF_SIZE_MAP = {
  '1-20 名': 'option1',
  '20-50 名': 'cz09glaXH',
  '50 名以上': 'mn2vgs3G4',
}

// demo注册页文案
export const DEMO_DESC = {
  Channel: {
    loginText:
      '/account/login/?scope=openid email profile&response_type=code&redirect_uri=https://demo-global.sensorsdata.com/api/oauth/auth?project=Basic_Demo&oauth_type=oauth&state=001oZSVs05&client_id=SensorsData&status=001oZSVs05',
    title: 'Channel Performance DEMO for Marketers',
    desc: {
      descTitle: 'From this demo you can get the way of:',
      list: [
        'Comprehensive performance insights for all marketing channels',
        'Enhanced ROI measurement for all marketing efforts',
        'Advertising and marketing strategy optimization',
        'Customer journey analysis across multiple touchpoints',
      ],
    },
  },
  Campaign: {
    loginText:
      '/account/login/?scope=openid email profile&response_type=code&redirect_uri=https://demo-global.sensorsdata.com/api/oauth/auth?project=Campaigns&oauth_type=oauth&state=00CM0CZW4C&client_id=SensorsData&status=00CM0CZW4C',
    title: 'Campaign Analysis DEMO for Marketers',
    desc: {
      descTitle: 'From this demo you can get the way of:',
      list: [
        'Targeted user acquisition with highly targeted segments',
        'Optimized conversion funnels with insights from data analytics',
        'Real-time performance monitoring of all their campaigns',
        'Enhanced attribution modeling of channels and touchpoints contribution',
      ],
    },
  },
  Retail: {
    loginText:
      '/account/login/?scope=openid email profile&response_type=code&redirect_uri=https://demo-global.sensorsdata.com/api/oauth/auth?project=default&product=sbp_family&oauth_type=oauth&state=/dashboard/?project=default&client_id=SensorsData&status=/dashboard/?project=default',
    title: 'Retail & E-commerce DEMO for All Teams',
    desc: {
      descTitle: 'From this demo you can get the way of:',
      list: [
        'Actionable customer insights into right data from all sources',
        'Advanced customer segmentation for highly personalized marketing campaigns',
        'Personalized messaging across multiple channels and devices',
        'Real-time engagement analytics for maximum effectiveness',
      ],
    },
  },
  ProductOptimize: {
    title: 'Product Analytics DEMO for PMs',
    desc: {
      descTitle: 'From this demo you can get the way of：',
      list: [
        'Identify user behavior patterns and trends.',
        'Optimize feature usage and adoption rates.',
        'Enhance user experience through data-driven insights.',
        'Improve retention with targeted engagement strategies.',
      ],
    },
  },
  IDMapping: {
    title: 'ID Mapping DEMO for All Teams',
    desc: {
      descTitle: 'From this demo you can go through：',
      list: [
        'Real-time, cross-device/platform/channel user identification to establish a comprehensive customer profile.',
        'The visual report that shows a user behavior sequence cross different devices, platforms and channels',
      ],
    },
  },
}

// 注册表单页面
export const FORM_DATA_LIST = [
  'Contact you to understand your specific needs and current situation.',
  'Assist you in making operational modifications in GTM, integrating SensorsData Web SDK.',
  'Help you set up pre-configured data dashboards',
  'Provide you with an free trail that includes your real data and business scenarios.',
]

// 线上平台选择
export const PLATFORMS_OPTIONS = [
  { value: '1', label: 'Web only' },
  { value: '2', label: 'Mobile App only' },
  { value: '3', label: 'Both Web and Mobile App' },
  { value: '4', label: 'More than Web and Mobile App' },
  { value: '5', label: 'Neither' },
  { value: 'other', label: 'Others' },
]
// 是否在使用GA4
export const IS_USING_GA4 = [
  { value: '1', label: 'Yes, we are using GA4 for our Web analysis' },
  {
    value: '0',
    label: 'Not yet, but we are considering a tool like GA and Sensors Data to analyze our Web or mobile App',
  },
]
// GA4 版本
export const GA4_VERSION = [
  { value: '1', label: 'Free version（GA4 Standard）' },
  { value: '0', label: 'Paid version（GA4 360）' },
]
// GA4 自定义事件
export const CUSTOM_EVENT_IN_GA4 = [
  { value: '1', label: 'Yes, we did some that are not the automatically collected or recommended events by GA.' },
  { value: '0', label: 'Not yet, events automatically collected by GA can meet our needs' },
]
