import React, { useState, useEffect, useMemo, ReactNode } from 'react'
import Seo from 'components/EN/Seo'
import Layout from 'layouts/en'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import * as styles from './index.module.less'
import { Form, Input, Button, Checkbox, Spin, notification } from 'sensd'
import 'sensd/dist/sensd.css'
import jwt_decode from 'jwt-decode'

import { checkEmail, fetchRegisterI18n, fetchEnLogin } from 'api/user'
import { PolicyModal } from 'components/Form'
import { saTrackLoginAndSignup, saSetUserStats } from 'utils/statistics'
import { removeEmptyValue } from 'utils/utils.js'
import { ActiveEmail, VerifyEmail, ActiveError, SuccessCom, Form2 } from '../login/index.en'
import googleIcon from '../../../assets/images/account/googleIcon.png'
import LoginRedirect from 'components/Account/LoginRedirect'
import { DEMO_DESC } from '../../../assets/js/constant.js'
import emailIcon from '../../../assets/images/form/parade/email.png'
import phoneIcon from '../../../assets/images/form/parade/phoneIcon.png'
import qrCode from '../../../assets/images/form/parade/qrcode.png'

const FormItem = Form.Item

// const envHostMap = {
//   development: 'http://localhost:8000',
//   test: 'https://wwwtest.sensorsdata.com',
//   release: 'https://www.sensorsdata.com',
//   production: 'https://www.sensorsdata.com',
// }

const Form3 = ({ data, setStep, setData, setLoading }) => {
  const [form3] = Form.useForm()
  const [status, setStatus] = useState<'' | 'error'>('')
  const [help, setHelp] = useState<ReactNode>(null)
  const [agreeProtocol, setAgreeProtocol] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { demo } = data

  const handleShowPolicyModal = (e) => {
    e.preventDefault()
    setIsModalVisible(true)
  }

  const clientId = '518291784352-ksedve4kd68114mdbs4sba9jc0i1l1t1.apps.googleusercontent.com'
  const loadGoogleJs = () => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    document.head.appendChild(script)
  }
  // 谷歌授权登录
  const handleCredentialResponse = async (response) => {
    const jwt = response.credential
    // 解码拿到谷歌邮箱
    const userInfo: any = jwt_decode(jwt)
    const { email } = userInfo
    try {
      setLoading(true)
      // 检查谷歌邮箱是否已经注册
      const responseData = await checkEmail(email)
      if (responseData.data.result) {
        // 已注册过 -> 走登录
        const verify = await fetchEnLogin(removeEmptyValue({ email, type: 1, jwt }))
        if (verify.status === 200) {
          setStep('success')
          setLoading(false)
        }
      } else {
        // 未注册-直接调用登录接口，type传1
        const res = await fetchEnLogin({ jwt, type: 1 })
        const { result } = res.data
        if (result.email) {
          // 未注册-跳转到补全信息
          setData({ ...data, email })
          setStep('form2')
          setLoading(false)
        } else {
          setStatus('error')
          setHelp(
            <>
              You already have an account.
              <a className={styles.errorA} href={demo ? `/account/login?demo=${demo}` : `/account/login`}>
                {` Click here `}
              </a>
              to sign in.
            </>,
          )
          setLoading(false)
        }
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    loadGoogleJs()
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      // @ts-ignore
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse,
      })
      // @ts-ignore
      window.google.accounts.id.renderButton(document.getElementById('buttonDiv'), {
        theme: 'outline',
        size: 'large',
        width: '350',
      })
    }
  }, [])

  const handleValuesChange = (changedValues) => {
    // 当邮箱输入框的值发生变化时，清除错误信息
    if ('email' in changedValues) {
      setHelp(null)
    }
  }
  const onFinish = async (value) => {
    setLoading(true)
    try {
      const res = await checkEmail(value.email)
      if (res.status === 200) {
        saSetUserStats({ Email: value.email })
        saTrackLoginAndSignup('English_ClickContinue')
        setData({
          ...data,
          email: value.email,
        })
      }
      // false-未注册 true：注册了
      const { result } = res.data
      // 已注册过 -> 走登录
      if (result) {
        // 通过工作邮箱登录，需要传type 为 2
        const verify = await fetchEnLogin(removeEmptyValue({ email: value.email, type: 2, demo }))
        if (verify.status === 200) {
          setStep('verifyEmail')
          setLoading(false)
        }
        // 未注册 -> 走补全信息 注册
      } else {
        const urlPathname = window.location.pathname
        const urlHost = process.env.ENV_HOST
        const redirectUrl = `${urlHost}${urlPathname}?step=form2`
        const registerResult = await fetchRegisterI18n({ demo: data.demo, email: value.email, redirectUrl })
        const isRegister = registerResult.data.success
        isRegister && setStep('activeEmail')
        setLoading(false)
      }
    } catch (err) {
      notification['error']({
        message: 'Something went wrong, please try again later.',
      })
      setStep('activeError')
      setLoading(false)
    }
  }

  return (
    <div>
      <div className={classnames('mb-[40px]', styles.formTitle)}> Sign up/in for demo</div>
      <Form form={form3} name="form3" onFinish={onFinish} onValuesChange={handleValuesChange}>
        <FormItem
          name="email"
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              type: 'email',
              message: 'Please enter a valid email!',
            },
          ]}
          validateTrigger="onBlur"
          hideErrorWhenChange
          validateStatus={status}
          help={help}
        >
          <Input name="email" placeholder="Work email *" />
        </FormItem>

        <FormItem
          name="protocol"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject('This field is required.')),
            },
          ]}
        >
          <Checkbox
            value={agreeProtocol}
            onChange={(e) => {
              setAgreeProtocol(e.target.checked)
            }}
          >
            <span className={styles.protocol}>
              I agree to Sensors Data's{` `}
              <span className={styles.lightText} onClick={handleShowPolicyModal}>
                Privacy Policy
              </span>
              .
            </span>
          </Checkbox>
        </FormItem>

        <FormItem>
          <Button className={styles.continueBtn} form="form3" size="large" type="primary" htmlType="submit">
            Continue
          </Button>
        </FormItem>
      </Form>
      <div className={classnames('mt-[40px]', styles.orLine)}>
        <div className={styles.orText}>or</div>
      </div>
      <div
        id="buttonDiv"
        className={classnames(styles.googleButton, {
          // [styles.zIndexF2]: !agreeProtocol,
          [styles.zIndex99]: true,
        })}
      ></div>
      <div className={styles.replaceGoogleBtn}>
        <div className={styles.btnContent}>
          <img src={googleIcon} alt="googleIcon" />
          <div className={styles.btnText}>Sign up with Google</div>
        </div>
      </div>
      <div className={classnames('mt-[82px]', styles.tips)}>
        Already have an account?
        <span
          className={styles.lightText}
          onClick={() => {
            window.location.href = demo ? `/account/login?demo=${demo}` : `/account/login`
          }}
        >
          {' '}
          Sign in
        </span>
        .
      </div>
      <PolicyModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
    </div>
  )
}

export const RenderContent = (step, setStep, data, setData, setLoading, isMb) => {
  switch (step) {
    case 'form3':
      return <Form3 setStep={setStep} data={data} setData={setData} setLoading={setLoading} />
    case 'activeEmail':
      return <ActiveEmail data={data} isMb={isMb} />
    case 'verifyEmail':
      return <VerifyEmail data={data} isMb={isMb} />
    case 'activeError':
      return <ActiveError isMb={isMb} />
    case 'success':
      return <SuccessCom />
    case 'form2':
      return <Form2 isMb={isMb} data={data} setStep={setStep} setLoading={setLoading} />
  }
}

const SignUpEn = (props) => {
  const isMb = useIsWindowWidthSmaller()

  // 步骤：form1-登陆页面；activeEmail-激活邮件；verifyEmail-验证邮件；activeError-激活失败；succuss-成功；form2-补全信息;form3-注册页面
  const [step, setStep] = useState('form3')

  const [data, setData] = useState<any>({})

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const email = searchParams.get('email')
    const step = searchParams.get('step')
    const demo = searchParams.get('demo')
    step && setStep(step)
    setData({ ...data, email, demo })
  }, [])

  const content = useMemo(() => {
    return RenderContent(step, setStep, data, setData, setLoading, isMb)
  }, [step, isMb, data])

  const DirectlyBy = () => {
    return (
      <div className={styles.directlyBy}>
        <div className={styles.directlyByLeft}>
          <div>
            <div className={styles.directlyByIconWrapper}>
              <img className={styles.directlyByEmailIcon} src={emailIcon} alt="emailIcon" />
              <div className={styles.directlyByIconDesc}>Email</div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.directlyByDesc}>contact@sensorsdata.com</div>
          </div>
          <div className="lg:mt-[23px] mt-[1.8rem]">
            <div className={styles.directlyByIconWrapper}>
              <img className={styles.directlyByPhoneIcon} src={phoneIcon} alt="phoneIcon" />
              <div className={styles.directlyByIconDesc}>Phone</div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.directlyByDesc}>+65 8591 3439</div>
          </div>
        </div>
        <div className={styles.whatsAppWrapper}>
          <img src={qrCode} alt="whatsAppIcon" className={styles.whatsAppIcon} />
          <div className={styles.whatsAppText}>WhatsApp</div>
        </div>
      </div>
    )
  }

  return (
    <Layout {...props} hideFooter showPhoneCall={false}>
      <Seo
        title="Registration - Sensors Data Accounts"
        description="Create a Sensors Data account to use digital products and services for your business."
        keywords="register for Sensors, create an account"
      ></Seo>
      <div className={styles.accountLoginWrapper}>
        <div className={styles.loginContentWrapper}>
          <div className={styles.accountLogin}>
            <div className={styles.rightContent}>
              {
                <div>
                  <div className={styles.demoTitle}>{DEMO_DESC[data.demo]?.title ?? DEMO_DESC['Retail'].title}</div>
                  <div className={styles.descTitle}>
                    {DEMO_DESC[data.demo]?.desc.descTitle ?? DEMO_DESC['Retail'].desc.descTitle}
                  </div>
                  <div className={styles.descList}>
                    {(DEMO_DESC[data.demo]?.desc.list ?? DEMO_DESC['Retail'].desc.list).map((item) => {
                      return (
                        <div className={styles.descListItem} key={item}>
                          <div className={styles.descListItemText}>{item}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
              <div className={styles.directlyByTitle}>You can also reach us for a demo request directly by</div>
              {isMb ? null : DirectlyBy()}
            </div>
            <div className={styles.formWrapper}>
              <div className={classnames(styles.loading, { [styles.toTop]: loading })}>
                {loading && <Spin size="large" />}
              </div>
              <div className={loading ? styles.contentWrapper : ''}>{content}</div>
            </div>
            <div>{isMb ? DirectlyBy() : null}</div>
          </div>
        </div>
      </div>
      <LoginRedirect />
    </Layout>
  )
}

export default SignUpEn
